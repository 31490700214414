import React from 'react'
import BarChartTemplate from 'Pages/Analytics/SaleAnalytics/Components/BarChart.Template'
import { useHistory, useParams } from 'react-router-dom'
import { getStore360Charts } from 'Services/Analytics/saleChannel-analytics'
import BarLineChartTemplate from 'Pages/Analytics/SaleAnalytics/Components/BarLineChart.Template'

const ChartRow2 = () => {
  const { id } = useParams()
  return (
    <>
      <div className="bg-white p-4 col-span-1 shadow-div_b">
        <div className="mb-4 font-semibold">% Discount</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 260 }}>
          <BarLineChartTemplate
            chartCode="discount_ratio"
            handleDataFunction={getStore360Charts}
            isHalf
          />
        </div>
      </div>
      <div className="bg-white p-4 col-span-1 shadow-div_b">
        <div className="mb-4 font-semibold">% Gross Profit</div>
        <div className="w-full overflow-y-scroll overflow-x-hidden" style={{ height: 260 }}>
          <BarLineChartTemplate
            chartCode="gross_profit_ratio"
            handleDataFunction={getStore360Charts}
            isHalf
          />
        </div>
      </div>
    </>
  )
}

export default ChartRow2
